import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LocaleNavLink from 'components/LocaleNavLink';
import StandardContainer from 'components/StandardContainer';
import {
    NEWS_ROOM,
    LOST_N_FOUND,
    ABOUT_US,
    SERVICE,
    PARTNERSHIPS,
} from 'constants/route';
import appleStoreZhIcon from 'assets/img/icons/ios_badge_ch.svg';
import appleStoreEnIcon from 'assets/img/icons/ios_badge_en.svg';
import googlePlayZhIcon from 'assets/img/icons/android_badge_ch.svg';
import googlePlayEnIcon from 'assets/img/icons/android_badge_en.svg';
import gogoroLogo from 'assets/img/gogoro-logo-small.svg';
import iconFB from 'assets/img/icons/icon-facebook.svg';
import iconInstagram from 'assets/img/icons/icon-instagram.svg';
import iconLinkedin from 'assets/img/icons/icon-linkedin.svg';
import iconMail from 'assets/img/icons/icon-mail.svg';
import './footer.scss';
import { getYear } from 'actions';

export const PRIVACY_POLICY_LINK = {
    ZH: 'https://www.gogoro.com/tw/privacy-policy/',
    EN: 'https://www.gogoro.com/privacy-policy/',
};

export const TERMS_OF_USE_LINK = {
    ZH: 'https://www.gogoro.com/tw/terms-of-use/',
    EN: 'https://www.gogoro.com/terms-of-use/',
};

export const TERMS_OF_SERVICE_LINK = {
    ZH: 'https://www.gogoro.com/tw/goshare-terms-of-use/',
    EN: 'https://www.gogoro.com/goshare-terms-of-use/',
};


const Component = ({ dispatch, i18n, match, ghostHeader, year }) => {
    const { locale } = i18n;
    const { url } = match;
    const isZh = ['zh', 'zh-TW'].indexOf(locale) > -1;
    const appleStoreIcon = (isZh ? appleStoreZhIcon : appleStoreEnIcon);
    const googlePlayIcon = (isZh ? googlePlayZhIcon : googlePlayEnIcon);
    const currentPath = url.replace(/\/[tw|us]\//g, '').split('/').pop();

    useEffect(() => {
        dispatch(getYear());
    }, [dispatch]);

    return (
        <StandardContainer tag="footer" className="footer" ghostHeader={ ghostHeader }>
            <section className="downloads">
                <img className="icon" src={ appleStoreIcon } alt="app store" />
                <img className="icon" src={ googlePlayIcon } alt="google play" />
            </section>
            <section className="contact">
                <LocaleNavLink to={ ABOUT_US }>
                    <Translate value="nav-about-us" />
                </LocaleNavLink>
                <LocaleNavLink to={ SERVICE }>
                    <Translate value="our-service" />
                </LocaleNavLink>
                <LocaleNavLink to={ PARTNERSHIPS }>
                    <Translate value="nav-partnerships" />
                </LocaleNavLink>
                <a href="mailto:partner@ridegoshare.com" target="_blank" rel="noopener noreferrer">
                    <Translate value="contact-us" />
                </a>
                <LocaleNavLink to={ NEWS_ROOM }>
                    <Translate value="newsroom" />
                </LocaleNavLink>
                <a
                    href="https://www.104.com.tw/company/ojonwyo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                >
                    <Translate value="career" />
                </a>
            </section>
            <section className="icons">
                <a href="mailto:support.tw@ridegoshare.com" className="icon" target="_blank" rel="noopener noreferrer">
                    <img alt="email" className="icon" src={ iconMail } />
                </a>
                <a href="https://www.facebook.com/GoShareTW/" className="icon" target="_blank" rel="noopener noreferrer">
                    <img alt="Facebook" src={ iconFB } />
                </a>
                <a href="https://www.instagram.com/goshare.official" className="icon" target="_blank" rel="noopener noreferrer">
                    <img alt="Instagram" className="icon" src={ iconInstagram } />
                </a>
                <a href="https://www.linkedin.com/company/ridegoshare" className="icon" target="_blank" rel="noopener noreferrer">
                    <img alt="Linkedin" className="icon" src={ iconLinkedin } />
                </a>
            </section>
            <section className="language">
                <a href={ `/tw/${ currentPath }` } className="lang">中文</a>
                <span>|</span>
                <a href={ `/us/${ currentPath }` } className="lang">English</a>
            </section>
            <a
                href={ isZh ? 'https://www.gogoro.com/tw/' : 'https://www.gogoro.com/' }
                className="gogoro-logo"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={ gogoroLogo } alt="gogoro logo" />
            </a>
            <p className="legal">
                <a
                    href={ isZh ? PRIVACY_POLICY_LINK['ZH'] : PRIVACY_POLICY_LINK['EN'] }
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Translate value="privacy-policy" />
                </a>
                <span>|</span>
                <a
                    href={ isZh ? 'https://www.gogoro.com/tw/goshare-data-notice/' : 'https://www.gogoro.com/goshare-data-notice/' }
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Translate value="goshare-data-notice" />
                </a>
                <span>|</span>
                <a
                    href={ isZh ? TERMS_OF_USE_LINK['ZH'] : TERMS_OF_USE_LINK['EN'] }
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Translate value="terms-and-conditions" />
                </a>
                <span>|</span>
                <a
                    href={ isZh ? TERMS_OF_SERVICE_LINK['ZH'] : TERMS_OF_SERVICE_LINK['EN'] }
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Translate value="terms-and-service" />
                </a>
                <span>|</span>
                <LocaleNavLink to={ LOST_N_FOUND } className="link">
                    <Translate value="lost_found" />
                </LocaleNavLink>
            </p>
            <p className="copyright">
                <Translate value="all-rights-reserved" year={ year ? year.year : new Date().getFullYear() } />
            </p>
        </StandardContainer>
    );
};

Component.propTypes = {
    ghostHeader: PropTypes.bool,
};
Component.defaultProps = {
    ghostHeader: true,
};

export default withRouter(connect(state => ({
    year: state.general.year,
    i18n: state.i18n
}))(Component));
